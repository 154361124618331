import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"
import Layout from "../components/layout"
import SEO from "../components/SEO"

export default ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Episodes"
      />
      <div>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
          <Link
              to={node.fields.slug}
              css={css`
                text-decoration: none;
                color: inherit;
              `}
          >
            <span
                css={css`
                  color: hsla(0,0%,0%,0.6);
                  margin-bottom: ${rhythm(1)};
                `}
             >
            {node.frontmatter.date}
            </span>
            <h3
              css={css`
                margin-bottom: ${rhythm(1)};
              `}
            >
              {node.frontmatter.title}{" "}
            </h3>
          </Link>
          <div dangerouslySetInnerHTML={{ __html: node.html }} />
          <hr css={css`
            margin-bottom: ${rhythm(2)}
          `}/>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt,
          html
        }
      }
    }
  }
`